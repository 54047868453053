.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .service {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: $body-font;
        width: 15rem;
        height: 15rem;
        margin: 0.5rem;
        border-radius: 0;
        color: #ffffff;
        font-size: 2.25rem;
        font-weight: bolder;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        svg {
            margin: 0.5rem 0;
            font-size: 6.5rem;
        }
    }
    .service:nth-child(odd) {
        background-color: #ffac63;
    }
    .service:nth-child(even) {
        background-color: #63b1ff;
    }
    .service:nth-child(3n) {
        background-color: #9bff19;
    }
    .service:nth-child(4n) {
        background-color: #a163ff;
    }
    .service:hover {
        background-color: #afd7ff;
    }
}

@media (max-width: 440px) {
    .services {
        .service {
            width: 9rem;
            height: 9rem;
            margin: 0.5rem;
            font-size: 1.3rem;
            padding: 0.5rem;
            svg {
                margin: 0.5rem 0;
                font-size: 4rem;
            }
        }
        .service:nth-child(odd) {
            background-color: #ffac63;
        }
        .service:nth-child(even) {
            background-color: #63b1ff;
        }
        .service:nth-child(3n) {
            background-color: #9bff19;
        }
        .service:nth-child(4n) {
            background-color: #a163ff;
        }
        .service:nth-child(7n) {
            background-color: #63b1ff;
        }
    }
}
