.testimonials {
    .testimonial {
        display: block;
        padding: 1rem;
        text-align: right;
        margin: 1rem auto;
        .author {
            display: block;
            margin-top: 1rem;
            font-weight: bold;
            font-style: italic;
        }
    }
    .testimonial:nth-child(odd) {
        background-color: #ffac63;
    }
    .testimonial:nth-child(even) {
        background-color: #63b1ff;
    }
    .testimonial:nth-child(3n) {
        background-color: #bbff63;
    }
    .testimonial:nth-child(4n) {
        background-color: #a163ff;
    }
}
