.modal {
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);

    .modal-content {
        display: block;
        width: 65%;
        margin: 2rem auto;
        padding: 1rem;
        background-color: #fff;
        max-height: calc(100vh - 10rem);
        .exit {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ce0000;
            border: none;
            height: 3rem;
            width: 3rem;
            font-size: 1.5rem;
            font-weight: bolder;
            color: #fff;
            cursor: pointer;
        }
        .modal-body {
            display: block;
            font-size: 1.5rem;
            font-weight: normal;
            padding: 0.5rem;
            max-height: calc(100vh - 20rem);
            overflow-y: auto;
            table {
                max-height: 20rem;
                overflow-y: auto;
            }
            .form-group {
                margin: 0.2rem;
            }
            h1,
            h2,
            h3,
            h4,
            h5 {
                margin: 1rem 0;
            }
        }
        .modal-footer {
            margin-top: 0.5rem;
            border-top: 0.1rem solid #888;
            padding-top: 0.5rem;
            display: flex;
            justify-content: space-between;
            small {
                width: 100%;
                line-height: 2.4rem;
            }
        }
    }
    .modal-content.modal-wide {
        width: 75%;
    }
}

@media (max-width: 440px) {
    .modal {
        .modal-content {
            margin: 1rem auto;
            width: 75%;
            max-height: calc(100vh - 10rem);
            .exit {
                font-size: 1rem;
                height: 2rem;
                width: 2rem;
            }
            .modal-body {
                max-height: calc(100vh - 10rem);
                h2 {
                    font-size: 1.3rem;
                }
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
