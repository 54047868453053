
$header-font: "Rammetto One", cursive;
$body-font: "Lato", sans-serif;

.slide {
    height: 100vh;
    background-color: rgb(143, 143, 143);
    h1 {
        position: absolute;
        bottom: 2.5rem;
        left: 5rem;
        font-size: 6rem;
        color: #fff;
        filter: drop-shadow(10px 10px 10px #000)
    }
    img {
        height: 100vh;
        opacity: 0.5;
    }
}

@media (max-width: 440px) {
    .slide {
        h1 {
            position: absolute;
            bottom: 2rem;
            left: 2.5rem;
            font-size: 3rem;
            color: #fff;
            filter: drop-shadow(10px 10px 10px #000)
        }
        img {
            width: 215vw !important;
        }
    }
}
