@import url("https://fonts.googleapis.com/css2?family=Lato&family=Rammetto+One&display=swap");
$header-font: "Rammetto One", cursive;
$body-font: "Lato", sans-serif;
@import "carousel";
@import "testimonial";
@import "services";
@import "modal";

.logo {
    font-family: $header-font;
}

.button-bar {
    display: flex;
    justify-content: right;
}

.floating {
    display: block;
    position: absolute;
    top: 10rem;
    right: 5rem;
    z-index: 1000;
    font-size: 8rem;
    margin: 0;
    color: #fff;
    padding: 0;
    filter: drop-shadow(10px 10px 10px #000);
}

header {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-bottom: 2px solid #000;
}

nav {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background: #000;
    height: 3rem;
    a {
        font-weight: bold;
        text-decoration: none;
        color: #fff;
        margin: 0 1rem;
    }
    a:hover,
    a:active {
        color: rgb(99, 175, 255);
    }
    .logo {
        font-size: 2rem;
    }
}

body {
    margin: 0;
    font-family: $body-font;
    font-size: 1.2rem;
}

.main {
    margin: 0 15rem;
    margin-top: 4rem;
    text-align: center;
}

.mobile {
    display: none;
}

.menuBtn {
    display: none;
}

.dropdown {
    display: none;
}

@media (max-width: 440px) {
    .floating {
        top: 5rem;
        left: 10vw;
        font-size: 5rem;
    }
    .main {
        margin: 0 1rem;
        margin-top: 4rem;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: flex;
        justify-content: center;
    }
    .menuBtn {
        display: block;
        cursor: pointer;
        position: absolute;
        left: 1rem;
    }
    .dropdown {
        display: block;
        position: absolute;
        top: 1.8rem;
        z-index: 1001;
        width: 100%;
        background-color: #fff;
        padding: 0;

        a {
            text-decoration: none;
            color: #000;
            li {
                padding: 0.5rem 1rem;
                list-style: none;
                border-bottom: 1px solid #000;
            }
            li:hover {
                background-color: #000;
            }
        }
        a:hover {
            color: rgb(45, 164, 255);
        }
    }
}
